<template>
	<v-sheet>
		<v-layout class="py-3 px-3">
			<v-flex class="py-0">
				<div class="sub-heading primary--text">Email Template</div>
			</v-flex>
			<v-spacer></v-spacer>
			<v-flex class="py-0 d-flex justify-content-end">
				<v-btn tile depressed class="ml-2" @click="$router.go(-1)">Cancel</v-btn>
				<v-btn
					tile
					depressed
					color="blue darken-4 white--text"
					class="ml-2"
					v-on:click="updateEmailTemplate(emailTemplate.id)"
					>Save</v-btn
				>
			</v-flex>
		</v-layout>
		<v-row>
			<v-col md="8" lg="9" class="email-template-scroll">
				<div class="mb-5 d-flex">
					<div class="w-200 my-auto px-2">
						<label for="" class="fw-500">Template Name</label>
					</div>
					<v-flex md9>
						<v-text-field
							hide-details
							outlined
							placeholder="Template Name"
							v-model="emailTemplate.template"
						></v-text-field>
					</v-flex>
				</div>
				<div class="mb-5 d-flex">
					<div class="w-200 my-auto px-2">
						<label for="" class="fw-500">Subject</label>
					</div>
					<v-flex md9>
						<v-text-field
							hide-details
							outlined
							placeholder="Subject"
							v-model="emailTemplate.subject"
						></v-text-field>
					</v-flex>
				</div>
				<div class="mb-5 d-flex">
					<div class="w-200 pa-2">
						<label for="" class="fw-500">Email Body</label>
					</div>
					<v-flex md9>
						<ckeditor :editor="editor" v-model="emailTemplate.body" :config="editorConfig"></ckeditor>
					</v-flex>
				</div>
				<!-- <div class="mb-5 d-flex">
					<div class="w-200 pa-2">
						<label for="" class="fw-500">Email Body</label>
					</div>
					<v-flex>
						<ckeditor :editor="editor" v-model="editorCustomerData" :config="editorConfig"></ckeditor>
					</v-flex>
				</div>
				<div class="mb-5 d-flex">
					<div class="w-200 pa-2">
						<label for="" class="fw-500">Staff</label>
					</div>
					<v-flex>
						<ckeditor :editor="editor" v-model="editorStaffData" :config="editorConfig"></ckeditor>
					</v-flex>
				</div> -->
				<div class="mb-5 d-flex">
					<div class="w-200 my-auto px-2">
						<label for="" class="fw-500">In-App Notification</label>
					</div>
					<v-flex md9>
						<v-text-field
							hide-details
							outlined
							placeholder="In-App Notification"
							v-model="emailTemplate.in_app"
						></v-text-field>
					</v-flex>
				</div>
			</v-col>
			<v-col md="4" lg="3" class="email-template-scroll">
				<div class="sub-heading primary--text mb-3">Variables</div>
				<div class="fw-500 mb-3 pointer" v-for="n in 5" :key="n">
					<v-tooltip top content-class="custom-top-tooltip">
						<template v-slot:activator="{ on, attrs }">
							<span v-on="on" v-bind="attrs" v-text="`{{Variable_name ${n}}}`"></span>
						</template>
						<span>Click to Copy</span>
					</v-tooltip>
				</div>
			</v-col>
		</v-row>
	</v-sheet>
</template>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { GET, PATCH } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";

export default {
	name: "EmailTemplate",
	data() {
		return {
			template_name: null,
			subject: null,
			in_app_notification: null,
			editor: ClassicEditor,
			editorAdminData: "<p>Content of the editor.</p>",
			editorCustomerData: "<p>Content of the Customer Data.</p>",
			editorStaffData: "<p>Content of the Staff Data.</p>",
			editorConfig: {},
			templateId: null,
			emailTemplate: {
				template: null,
				subject: null,
				body: null,
				in_app: null,
			},
		};
	},
	methods: {
		getSingleTemplate() {
			this.$store
				.dispatch(GET, { url: `setting/single-email-template/${this.templateId}` })
				.then((data) => {
					this.emailTemplate = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		updateEmailTemplate(id) {
			this.pageLoading = true;
			this.$store
				.dispatch(PATCH, {
					url: `setting/email-template/${id}`,
					data: this.emailTemplate,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Email setting updated successfully." },
					]);
					this.$router.push({
						name: "setting",
						params: { tab: "email_template" },
					});
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	mounted() {
		this.getSingleTemplate();
	},
	beforeMount() {
		this.templateId = this.$route.params.id;
	},
};
</script>
